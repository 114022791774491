import React, { useContext } from 'react'
import styles from './Footer.module.css';
import { AiOutlineMail } from 'react-icons/ai';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { AppContext } from '../../AppContext';

const Footer = props => {

    const appContext = useContext(AppContext);

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>
                <div className={styles['section-top']}>
                    <div className={styles.left}>
                        <img alt="logo" src={require('../../assets/images/logo.png')} />
                        <span className={styles['desc']}>
                            Merqury Technology Limited is a company registered in England and Wales (company number 14889545).
                        </span>                        
                    </div>
                    <div className={styles.right}>
                        <span onClick={() => appContext.setShowLegalModal("privacy")}>Privacy Policy</span>
                        <span onClick={() => appContext.setShowLegalModal("tos")}>Terms of Service </span>
                        <div className={styles['info-list']}>
                            <div className={styles['info-item']}>
                                <div className={styles['icon-wrapper']}>
                                    <AiOutlineMail />
                                </div>
                                <span>hello@merqury.co.uk</span>
                            </div>
                        </div>
                        <span className={styles['contact-us']} onClick={() => appContext.setShowContactModal(true)}>Contact us <HiOutlineArrowNarrowRight /></span>
                    </div>
                </div>
                <div className={styles['section-bottom']}>
                    <span>All right reserved © 2023.</span>
                </div>
            </div>
        </div>
    )
}

export default Footer